.topSection {}

.row {
  width: 100%;
}

.column {
  width: 100%;
  padding: 0;
}

.title {
  text-align: center;
  margin-top: 30px;
}

.bio {
  width: 100%;
  font-size: 2em;
  font-family: 'Acme', sans-serif;
  color: black;
}

.bioText {
  min-width: 350px;
  width: 80%;
  padding: 50px;
  margin: auto;
  color: rgb(167,210,196);
}

.scrollingBanner {
  height: 50px;
  background-color: black;
}
