.video-poster-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  transform: rotate(-7deg);
  color: black;

}

.background-image {
  width: 70%;
  z-index: 1;
  flex-shrink: 0;
  object-fit: cover;
  overflow: visible;
  box-shadow: 50px 35px 70px 0px;
  border-radius: 1%;
  background-color: rgb(54, 41, 31);
}


.video-wrapper {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 53%;
  /* Adjust the width as needed */
  overflow: hidden;
  z-index: 1;
  object-fit: cover;

}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
