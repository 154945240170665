/* Add your own styles */
.navigation {
  position: relative;
  font-family: 'Acme', sans-serif;
}

/* .hamburger-icon {
  width: 200px;
  cursor: pointer;
} */

.HamburgerContainer {
  height: 30px;
  width: 30px;
  cursor: pointer;
  transition: width 0.5s ease, height 0.7s ease;
}


.strip1 {
  width: 100%;
  height: 20%;
  background-color: rgb(167,210,196);
}
.strip2 {
  width: 100%;
  height: 20%;
}

.fold-out-menu {
  position: absolute;
  width: 70px;
  right: 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: rgb(63,112,131);
  color: rgb(167,210,196);
}

a {
  text-decoration: none;
  color: rgb(167,210,196);
}
 .menu-item {
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #f0f0f0; /* Adjust the hover background color */
}
