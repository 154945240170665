@import url('https://fonts.googleapis.com/css2?family=Delicious+Handrawn&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');

.color_palette{
    background-color: rgb(238,217,189);
    background-color: rgb(231,184,125);
    background-color: rgb(167,94,91);
    background-color: rgb(167,210,196);
    background-color: rgb(63,112,131);

}

.App {
  font-family: sans-serif;
  text-align: center;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(167,94,91);
}

body {
  width: 100%;
  height: 100%;
  min-width: 460px;
  margin: 0;
  padding: 0;
  color: rgb(167,210,196);
  /* background-color: rgb(97, 124, 154); */
}

h1 {
  font-family: 'Delicious Handrawn', cursive;
  font-size: 6em;
  text-align: left;
  margin-top: 0;
  margin-left: 20px;
  color: rgb(167,210,196);

}

h2 {
  font-family: 'Delicious Handrawn', cursive;
  font-size: 5em;
  margin: 0;
  color: rgb(167,210,196);

}

h3 {
  font-family: 'Acme', sans-serif;
  font-size: 2em;
  margin: 0;
  color: rgb(167,210,196);

}

h4 {
  font-family: 'Acme', sans-serif;
  font-size: 1em;
  margin: 0;
  color: rgb(167,210,196);
}

/*  */

.container-fluid {
  width: 100%;
  padding: 0;
  margin: 0;
}



.Promo {
  padding: 100px 0 100px;
}

.navigatorContainer {
  position: fixed;
  right: 50px;
  top: 60px;
  z-index: 10;
}
/* .pageContainer {
  height: 100%;
  animation: fadeIn 0.5s;
}
.page {
  flex: 1;
  width: 100vw;
  color: white;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.gigImage {
  width: 50px;
}

.fill {
  height: 500px;
}
